.masthead1 {
  min-height: 30rem;
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.masthead-sizes {
  height: 100vh;
}

.masthead-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 992px) {
  .masthead-sizes {
    max-width: 465px;
  }
}
