.my-Card {
  min-height: 30rem;
  position: relative;
  width: 100%;
  height: 100vh;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.card-sizes {
  max-width: 465px;
}

.my-shadow-1 {
  box-shadow: blue 0 0 0 2px inset, rgb(255, 255, 255) 10px -10px 0 -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0 -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0 -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0 -3px, rgb(255, 85, 85) 40px -40px;
}

.my-shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.17) 0 -23px 25px 0 inset, rgba(0, 0, 0, 0.15) 0 -36px 30px 0 inset, rgba(0, 0, 0, 0.1) 0 -79px 40px 0 inset, rgba(0, 0, 0, 0.06) 0 2px 1px, rgba(0, 0, 0, 0.09) 0 4px 2px, rgba(0, 0, 0, 0.09) 0 8px 4px, rgba(0, 0, 0, 0.09) 0 16px 8px, rgba(0, 0, 0, 0.09) 0 32px 16px;
}

.my-shadow-3 {
  box-shadow: rgba(240, 46, 170, 0.4) 0 22px 70px 4px;
}

@media (min-width: 992px) {
  .card-sizes {
    margin: 5px;
  }
}
