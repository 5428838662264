@font-face {
  font-family: "Castoro-Regular";
  src:
    local("Castoro-Regular"),
    url("./Assets/Fonts/Castoro/Castoro-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gelasio-Regular";
  src:
    local("Gelasio-Regular"),
    url("./Assets/Fonts/Gelasio/Gelasio-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Lusitana-Regular";
  src:
    local("Lusitana-Regular"),
    url("./Assets/Fonts/Lusitana/Lusitana-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Volkhov-Regular";
  src:
    local("Volkhov-Regular"),
    url("./Assets/Fonts/Volkhov/Volkhov-Regular.ttf") format("truetype");
  font-weight: normal;
}

.castoro {
  font-family: Castoro-Regular, sans-serif;
}

.gelasio {
  font-family: Gelasio-Regular, sans-serif;
}

.lusitana {
  font-family: Lusitana-Regular, sans-serif;
}

.volkhov {
  font-family: Volkhov, sans-serif;
}
